import { FieldArray } from "formik";
import moment from "moment";
import { useState, useEffect } from "react";
import { InputField } from "../../components/InputField";
import { SelectField } from "../../components/SelectField";
import { currencyFormatter } from "../../lib";

export default function HospiCash(props: any) {
  const { setFieldValue, values, isSingleProduct } = props;

  const [showAddFamilyModal, toggleAddFamilyModal] = useState(false);

  const [familyMember, updateFamilyMember] = useState<{
    family_member?: string;
    pernight_benefit?: any;
    dob?: any;
  }>({});

  useEffect(
    function () {
      if (showAddFamilyModal === false) {
        updateFamilyMember({});
      }
    },
    [showAddFamilyModal]
  );

  return (
    <>
      {!isSingleProduct && (
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <div className="form-check form-check-inline d-block">
              <input
                className="form-check-input"
                type="checkbox"
                value="hospital_cash_"
                name="hospital_cash_"
                onChange={(e: any) => {
                  setFieldValue("hospital_cash_", e.target.checked ? 1 : 0);
                  if (!e.target.checked) {
                    setFieldValue("hospital_cash_", undefined);
                    setFieldValue("hospital_cash", undefined);
                  } else {
                    setFieldValue("hospital_cash.cover_type", "individual");
                    setFieldValue(
                      "hospital_cash.principalnight_benefit",
                      30000
                    );
                    setFieldValue("hospital_cash.days_exclusion", "0-days");
                  }
                }}
                checked={values["hospital_cash_"] === 1}
              />
              <label className="form-check-label">Hospital cash</label>
            </div>
            <small>
              (Daily amount of money that you will be paid in case you or your
              family members are hospitalized in a NHIF facility for a total of
              30 days)
            </small>
          </div>
        </div>
      )}
      <div
        className={`collapse ${values["hospital_cash_"] === 1 ? "show" : ""}`}
        id="hospitalCash"
      >
        <div className="">
          {!isSingleProduct ? (
            <h6 className="card-subtitle mb-2 text-muted">
              Hospital Cash Options
            </h6>
          ) : (
            <>
              <div className="form-row">
                <div className="form-holder form-holder-2">
                  <InputField
                    label="What is your Date of Birth?"
                    type="date"
                    name="hospital_cash.principaldob"
                    className="form-control py-2"
                    required
                    max={moment()
                      .subtract(18, "years")
                      .subtract(1, "week")
                      .format("YYYY-MM-DD")}
                  />
                </div>
              </div>
              <hr />
            </>
          )}
          <div className="form-row mb-2">
            <div className="form-holder form-holder-2 fw-bold">
              <label>What type of cover do you want?</label>
            </div>
          </div>
          <div className="form-row mb-2">
            <div className="form-holder">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  name="hospital_cash.cover_type"
                  type="radio"
                  value="individual"
                  onChange={(e: any) => {
                    setFieldValue("hospital_cash.cover_type", "individual");
                  }}
                  checked={values?.hospital_cash?.cover_type === "individual"}
                />
                <label
                  className="form-check-label"
                  htmlFor="hospitalCashIndividual"
                >
                  Individual Cover
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  name="hospital_cash.cover_type"
                  type="radio"
                  value="extended"
                  onChange={(e: any) => {
                    setFieldValue("hospital_cash.cover_type", "extended");
                  }}
                  checked={values?.hospital_cash?.cover_type === "extended"}
                />
                <label className="form-check-label" htmlFor="familyCover">
                  Family Cover
                </label>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-holder form-holder-2">
              <InputField
                label=" How much would you like to receive every day for the time you
                are hospitalized?"
                type="number"
                name="hospital_cash.principalnight_benefit"
                className="form-control py-2"
                required
                max="30000"
              />
              <div className="invalid-feedback">Required</div>
            </div>
          </div>
          <div className="mb-0">
            <div className="form-holder form-holder-2 fw-bold mb-2">
              <label>Would you like the payment to start on</label>
            </div>
            <div className="form-holder mb-2">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="hospital_cash.days_exclusion"
                  id="inlineRadio1"
                  value="0-days"
                  onChange={(e: any) => {
                    setFieldValue("hospital_cash.days_exclusion", "0-days");
                  }}
                  checked={values?.hospital_cash?.days_exclusion === "0-days"}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  1st day of hospitalization
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="hospital_cash.days_exclusion"
                  id="inlineRadio2"
                  value="1-day"
                  onChange={(e: any) => {
                    setFieldValue("hospital_cash.days_exclusion", "1-day");
                  }}
                  checked={values?.hospital_cash?.days_exclusion === "1-day"}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  2nd Day of hospitalization{" "}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="hospital_cash.days_exclusion"
                  id="inlineRadio3"
                  value="2-days"
                  onChange={(e: any) => {
                    setFieldValue("hospital_cash.days_exclusion", "2-days");
                  }}
                  checked={values?.hospital_cash?.days_exclusion === "2-days"}
                />
                <label className="form-check-label" htmlFor="inlineRadio3">
                  3rd of Hospitalization{" "}
                </label>
              </div>
            </div>
          </div>

          {showAddFamilyModal && (
            <>
              <div
                className={`modal fade show ${
                  showAddFamilyModal ? "show" : ""
                }`}
                id="addFamilyModal"
                aria-labelledby="addFamilyMemberModalLabel"
                aria-hidden="true"
                data-bs-backdrop={`${showAddFamilyModal ? "static" : ""}`}
                data-bs-keyboard={`${showAddFamilyModal ? "false" : ""}`}
                tabIndex={-1}
                style={{ display: "block" }}
                role="dialog"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6
                        className="modal-title"
                        id="addFamilyMemberModalLabel"
                      >
                        Hospital Cash Family Cover
                      </h6>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => toggleAddFamilyModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body" id="familyCoverInputs">
                      <SelectField
                        name="family_member"
                        label="Which family member?"
                        className="form-select py-2"
                        required
                        value={familyMember?.family_member}
                        onChange={(e: any) => {
                          updateFamilyMember({
                            ...familyMember,
                            family_member: e?.target?.value,
                          });
                        }}
                      >
                        <option></option>
                        <option value="spouse">Spouse</option>
                        <option value="children">Child</option>
                        <option value="parent">Parent</option>
                        <option value="parent_inlaws">Parent in Law</option>
                      </SelectField>
                      {familyMember?.family_member === "spouse" && (
                        <>
                          <InputField
                            label="How much money would you like your spouse to receive every day for the time they are in hospital?"
                            type="number"
                            name="hospital_cash.spouse.pernight_benefit"
                            className="form-control py-2"
                            required
                            min="500000"
                            max="15000000"
                            value={familyMember?.pernight_benefit}
                            onChange={(e: any) => {
                              updateFamilyMember({
                                ...familyMember,
                                pernight_benefit: e?.target?.value,
                              });
                            }}
                          />
                          <InputField
                            label="Date of Birth"
                            type="date"
                            name={`hospital_cash.spouse.spouseDOB[${
                              values?.spouse?.length
                                ? values?.spouse?.length + 1
                                : 0
                            }]`}
                            className="form-control py-2"
                            required
                            max={moment()
                              .subtract(18, "years")
                              .subtract(1, "week")
                              .format("YYYY-MM-DD")}
                            value={familyMember?.dob}
                            onChange={(e: any) => {
                              updateFamilyMember({
                                ...familyMember,
                                dob: e?.target?.value,
                              });
                            }}
                          />
                        </>
                      )}
                      {familyMember?.family_member === "children" && (
                        <>
                          <InputField
                            label="How much money would you like your child to
                receive every day for the time they are in hospital?"
                            type="number"
                            name="hospital_cash.children.pernight_benefit"
                            className="form-control py-2"
                            required
                            min="500000"
                            max="15000000"
                          />
                          <InputField
                            label="Date of Birth"
                            type="date"
                            name={`hospital_cash.children.childrenDOB[${
                              values?.children?.length
                                ? values?.children?.length + 1
                                : 0
                            }]`}
                            className="form-control py-2"
                            required
                          />
                        </>
                      )}
                      {familyMember?.family_member === "parent" && (
                        <>
                          <InputField
                            label="How much money would you like your child to
                receive every day for the time they are in hospital?"
                            type="number"
                            name="hospital_cash.parent.pernight_benefit"
                            className="form-control py-2"
                            required
                            min="500000"
                            max="15000000"
                          />
                          <InputField
                            label="Date of Birth"
                            type="date"
                            name={`hospital_cash.parent.parentDOB[${
                              values?.parent?.length
                                ? values?.parent?.length + 1
                                : 0
                            }]`}
                            className="form-control py-2"
                            required
                            max={moment()
                              .subtract(18, "years")
                              .subtract(1, "week")
                              .format("YYYY-MM-DD")}
                          />
                        </>
                      )}
                      {familyMember?.family_member === "parent_inlaws" && (
                        <>
                          <InputField
                            label="How much money would you like your parent in law to
                receive every day for the time they are in hospital?"
                            type="number"
                            name="hospital_cash.parent_inlaws.pernight_benefit"
                            className="form-control py-2"
                            required
                            min="500000"
                            max="15000000"
                          />
                          <InputField
                            label="Date of Birth"
                            type="date"
                            name={`hospital_cash.parent_inlaws.parent_inlawsDOB[${
                              values?.parent_inlaws?.length
                                ? values?.parent_inlaws?.length + 1
                                : 0
                            }]`}
                            className="form-control py-2"
                            required
                            max={moment()
                              .subtract(18, "years")
                              .subtract(1, "week")
                              .format("YYYY-MM-DD")}
                          />
                        </>
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger"
                        id="btnAddFamilyMember"
                        onClick={(e) => {
                          if (familyMember.family_member) {
                            let totalCount = 0;
                            try {
                              totalCount = values?.hospital_cash[
                                familyMember.family_member
                              ][`${familyMember.family_member}DOB`]?.length
                                ? values?.hospital_cash[
                                    familyMember.family_member
                                  ][`${familyMember.family_member}DOB`]
                                    ?.length + 1
                                : 0;
                            } catch (e) {}
                            setFieldValue(
                              `hospital_cash.${familyMember.family_member}.pernight_benefit`,
                              familyMember.pernight_benefit
                            );
                            setFieldValue(
                              `hospital_cash.${familyMember.family_member}.${familyMember.family_member}DOB[${totalCount}]`,
                              familyMember.dob
                            );
                          }
                          toggleAddFamilyModal(false);
                        }}
                      >
                        Add Family Member
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        data-bs-dismiss="modal"
                        onClick={(e) => toggleAddFamilyModal(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </>
          )}
          <div
            id="familyCover"
            className={`collapse ${
              values?.hospital_cash?.cover_type === "extended"
                ? "show mt-2"
                : "mt-2"
            }`}
          >
            <div className="card mt-1">
              <div className="card-body">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#addFamilyMemberModal"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleAddFamilyModal(true);
                  }}
                >
                  Add Family Member
                </button>
                <table
                  className={`table ${
                    values?.hospital_cash?.cover_type === "extended"
                      ? "d-table"
                      : "d-none"
                  }`}
                  id="tableFamilyCover"
                >
                  <thead>
                    <tr>
                      <th scope="col">Family Member</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Date of Birth</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody id="familyList">
                    <FieldArray name="hospital_cash.spouse.spouseDOB">
                      {({ insert, remove, push }) => (
                        <>
                          {values?.hospital_cash?.spouse?.spouseDOB &&
                            values?.hospital_cash?.spouse?.spouseDOB?.length >
                              0 &&
                            values?.hospital_cash?.spouse?.spouseDOB.map(
                              (spouse: any, index: number) => (
                                <tr>
                                  <td>SPOUSE</td>
                                  <td>
                                    {values?.hospital_cash?.spouse
                                      ?.pernight_benefit
                                      ? currencyFormatter
                                          .format(
                                            values?.hospital_cash?.spouse
                                              ?.pernight_benefit
                                          )
                                          .toString()
                                      : currencyFormatter.format(0).toString()}
                                  </td>
                                  <td>{spouse}</td>
                                  <td className="text-danger">
                                    <button
                                      className="btn btn-link"
                                      onClick={() => remove(index)}
                                    >
                                      <u>Remove</u>
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                        </>
                      )}
                    </FieldArray>
                    <FieldArray name="hospital_cash.children.childrenDOB">
                      {({ insert, remove, push }) => (
                        <>
                          {values?.hospital_cash?.children &&
                            values?.hospital_cash?.children?.childrenDOB
                              ?.length > 0 &&
                            values?.hospital_cash?.children?.childrenDOB.map(
                              (child: any) => (
                                <tr>
                                  <td>CHILD</td>
                                  <td>
                                    {values?.hospital_cash?.children
                                      ?.pernight_benefit
                                      ? currencyFormatter
                                          .format(
                                            values?.hospital_cash?.children
                                              ?.pernight_benefit
                                          )
                                          .toString()
                                      : currencyFormatter.format(0).toString()}
                                  </td>
                                  <td>2004-08-03</td>
                                  <td className="text-danger">
                                    <u>Remove</u>
                                  </td>
                                </tr>
                              )
                            )}
                        </>
                      )}
                    </FieldArray>
                    <FieldArray name="hospital_cash.parent.parentDOB">
                      {({ insert, remove, push }) => (
                        <>
                          {values?.hospital_cash?.parent &&
                            values?.hospital_cash?.parent?.parentDOB?.length >
                              0 &&
                            values?.hospital_cash?.parent?.parentDOB?.map(
                              (parent: any) => (
                                <tr>
                                  <td>PARENT</td>
                                  <td>
                                    {values?.hospital_cash?.parent
                                      ?.pernight_benefit
                                      ? currencyFormatter
                                          .format(
                                            values?.hospital_cash?.parent
                                              ?.pernight_benefit
                                          )
                                          .toString()
                                      : currencyFormatter.format(0).toString()}
                                  </td>
                                  <td>2004-08-03</td>
                                  <td className="text-danger">
                                    <u>Remove</u>
                                  </td>
                                </tr>
                              )
                            )}
                        </>
                      )}
                    </FieldArray>
                    <FieldArray name="hospital_cash.parent_inlaws.parent_inlawsDOB">
                      {({ insert, remove, push }) => (
                        <>
                          {values?.hospital_cash?.parent_inlaws &&
                            values?.hospital_cash?.parent_inlaws
                              ?.parent_inlawsDOB?.length > 0 &&
                            values?.hospital_cash?.parent_inlaws?.parent_inlawsDOB?.map(
                              (pinl: any) => (
                                <tr>
                                  <td>PARENT IN LAW</td>
                                  <td>
                                    {values?.hospital_cash?.parent_inlaws
                                      ?.pernight_benefit
                                      ? currencyFormatter
                                          .format(
                                            values?.hospital_cash?.parent_inlaws
                                              ?.pernight_benefit
                                          )
                                          .toString()
                                      : currencyFormatter.format(0).toString()}
                                  </td>
                                  <td>2004-08-03</td>
                                  <td className="text-danger">
                                    <u>Remove</u>
                                  </td>
                                </tr>
                              )
                            )}
                        </>
                      )}
                    </FieldArray>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
