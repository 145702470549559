/* eslint-disable jsx-a11y/anchor-is-valid */
import { ErrorMessage, Field } from "formik";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { useWizard } from "react-use-wizard";
import * as Yup from "yup";
import { InputField } from "../components/InputField";
import { Modal } from "../components/Modal";
import { SelectField } from "../components/SelectField";

const paymentFrequency = ["monthly", "quarterly", "half-yearly", "yearly"];
const premiumTerms = {
  "whole life": "My whole life",
  "to age 65": "Till when I am 65 years old",
  "to age 60": "Till when I am 60 years old",
  "20 years": "For the next 20 years",
  "15 years": "For the next 15 years",
  "10 years": "For the next 10 years",
};
const amountType = ["affordable-premium", "target-amount"];
const planType = ["plan-a", "plan-b"];

export const PRULifeInitialValues = {
  plan_type: "plan-a",
  cover: 1,
  applicant: {
    status: "non-smoker",
    gender: "male",
  },
  amount_type: "target-amount",
  application_type: "single",
};

export const PRULifeValidationSchema = {
  cover: Yup.string(),
  plan_type: Yup.string().oneOf(planType).defined().required("Required"),
  premium_term: Yup.string()
    .oneOf(_.keys(premiumTerms))
    .defined("Required")
    .required("Required"),
  applicant: Yup.object().shape({
    dob: Yup.date()
      .max(
        moment().subtract(18, "years"),
        "You must be older than 18 years old"
      )
      .required("Required"),
    gender: Yup.string(),
    status: Yup.string().required("Required"),
  }),
  secondlife: Yup.object().shape({
    dob: Yup.date().max(moment().subtract(18, "years"), "Must be 18 and older"),
    status: Yup.string(),
  }),
  payment_frequency: Yup.mixed()
    .oneOf(paymentFrequency)
    .defined("Required")
    .required("Required"),
  amount_type: Yup.string()
    .oneOf(amountType)
    .defined("Required")
    .required("Required"),
  amount_value: Yup.number()
    .required("Required")
    .min(1000000, "Minimum amount is KES: 1M")
    .max(80000000, "Maximum amount is KES: 80M"),
};

export const PRULifeHeader = (props: any) => {
  const { activeStep } = useWizard();

  return (
    <div className="steps clearfix">
      <ul id="tabControls" role="tablist">
        <li
          role="tab"
          className={`${activeStep === 0 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-0"
            aria-controls="form-quote-p-0"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Your Goals</span>
            </div>
          </a>
        </li>
        <li
          role="tab"
          className={`${activeStep === 1 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-0"
            aria-controls="form-quote-p-0"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Optional Benefits</span>
            </div>
          </a>
        </li>
        <li
          role="tab"
          className={`${activeStep === 2 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-2"
            aria-controls="form-quote-p-2"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Personal Details</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default function PRULifeForm(props: any) {
  const { handleChange, handleBlur, setFieldValue, values, errors } = props;
  const [isSecondLifeModalOpen, setIsSecondLifeModalOpen] = useState(false);

  return (
    <section className="content">
      <div className="inner">
        <div className="form-row">
          <div className="form-holder">
            <InputField
              label="What is your Date of Birth?"
              type="date"
              name="applicant.dob"
              className="form-control py-2"
              required
              max={moment()
                .subtract(18, "years")
                .subtract(1, "week")
                .format("YYYY-MM-DD")}
            />
          </div>
          <div className="form-holder">
            <SelectField
              name="applicant.status"
              label="Smoking Status"
              required
            >
              <option value="non-smoker">Non-Smoker</option>
              <option value="smoker">Smoker</option>
            </SelectField>
          </div>
        </div>
        <div className="form-row">
          <div className="form-holder">
            <label>Choose Plan</label>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="plan_type"
                  value="plan-a"
                  required
                  onChange={(e: any) => {
                    setFieldValue("plan_type", "plan-a");
                  }}
                  onBlur={handleBlur}
                  checked={values["plan_type"] === "plan-a"}
                />
                Plan A (Whole Life)
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="plan_type"
                  value="plan-b"
                  required
                  onChange={(e: any) => {
                    setFieldValue("plan_type", "plan-b");
                  }}
                  onBlur={handleBlur}
                  checked={values["plan_type"] === "plan-b"}
                />
                Plan B (Whole Life with Critical Illness Cover)
              </label>
            </div>
            <ErrorMessage name="plan_type">
              {(msg) => <small className="invalid-feedback">{msg}</small>}
            </ErrorMessage>
          </div>

          <div className="form-holder">
            <label>Who do you want to cover in this policy?</label>
            <ErrorMessage name="cover">
              {(msg) => <small className="invalid-feedback">{msg}</small>}
            </ErrorMessage>
            {values.cover && values.cover === 2 ? (
              <>
                <button
                  type="button"
                  className="btn btn-success btn-sm me-3"
                  onClick={(e) => {
                    e.preventDefault();
                    const dob = values.secondlife?.dob;
                    const status = values.secondlife?.status;
                    alert(
                      `Spouse Details\n\nDate of Birth: ${dob}\nStatus: ${_.startCase(
                        status
                      )}`
                    );
                  }}
                >
                  View Spouse Details
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    const dob = values.secondlife?.dob;
                    const status = values.secondlife?.status;
                    if (
                      window.confirm(
                        `Your Spouse's details will be removed Spouse Details\n\nDate of Birth: ${dob}\nStatus: ${_.startCase(
                          status
                        )}`
                      ) === true
                    ) {
                      setFieldValue("secondlife", undefined);
                      setFieldValue("cover", 1);
                      setFieldValue("application_type", "single");
                      alert("Your Spouse's details have been removed.");
                    }
                  }}
                >
                  Select Only You
                </button>
              </>
            ) : (
              <>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="cover"
                      value="1"
                      required
                      onChange={(e: any) => {
                        setFieldValue("application_type", "single");
                        setFieldValue("secondlife", undefined);
                        handleChange(e);
                        setFieldValue("cover", 1);
                      }}
                      checked={values["cover"] === 1}
                    />
                    Only Me
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="cover"
                      value="2"
                      required
                      onChange={(e: any) => {
                        setIsSecondLifeModalOpen(true);
                        handleChange(e);
                        setFieldValue("application_type", "joint");
                        setFieldValue("cover", 2);
                      }}
                      checked={values["cover"] === 2}
                    />
                    Spouse and I{" "}
                  </label>
                </div>
              </>
            )}
            <Modal
              isOpened={isSecondLifeModalOpen}
              canSubmit={
                errors?.secondlife?.dob || errors?.secondlife?.status
                  ? false
                  : true
              }
              title="Spouse Details"
              handleClose={() => {
                setFieldValue("application_type", "single");
                setFieldValue("cover", "1");
                setIsSecondLifeModalOpen(false);
              }}
              handleSubmit={(e: any) => {
                setIsSecondLifeModalOpen(false);
              }}
            >
              <div className="form-row">
                <div className="form-holder">
                  <InputField
                    label="Spouse Date of Birth"
                    type="date"
                    name="secondlife[dob]"
                    className="form-control py-2"
                    required
                    max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                  />
                </div>
                <div className="form-holder">
                  <SelectField name="secondlife[status]" label="Smoking Status">
                    <option></option>
                    <option value="non-smoker">Non-Smoker</option>
                    <option value="smoker">Smoker</option>
                  </SelectField>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <InputField
              label="What total amount of money do you want your family to receive from your PRULife Policy to help them carry on with their
                                  lives?"
              type="number"
              name="amount_value"
              className="form-control py-2"
              required
              min="1000000"
              max="80000000"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-holder">
            <SelectField
              name="premium_term"
              className="form-select py-2"
              required
              label="For how long do you want to pay for
                                your policy?"
            >
              <option></option>
              {_.map(Object.entries(premiumTerms), (term) => {
                return <option value={term[0]}>{term[1]}</option>;
              })}
            </SelectField>
          </div>
          <div className="form-holder">
            <SelectField
              name="payment_frequency"
              className="form-select py-2"
              required
              label="How frequently do you want to make payments?"
            >
              <option></option>
              {paymentFrequency.map((pf) => (
                <option value={pf}>{_.startCase(pf)}</option>
              ))}
            </SelectField>
          </div>
        </div>
      </div>
      {isSecondLifeModalOpen && (
        <div className="modal-backdrop fade show"></div>
      )}
    </section>
  );
}
