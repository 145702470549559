/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field } from "formik";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { useWizard } from "react-use-wizard";
import * as Yup from "yup";
import { InputField } from "../components/InputField";
import { SelectField } from "../components/SelectField";

const amountType = ["affordable-premium", "target-amount"];

export const PRUMemorialInitialValues = {
  amount_type: "target-amount",
  application_type: "single",
  payment_frequency: "monthly",
};

export const PRUMemorialValidationSchema = {
  applicant_dob: Yup.date()
    .required("Your date of birth is required")
    .max(moment().subtract(18, "years"), "You must be older than 18 years old"),
  add_spouse_: Yup.number(),
  spouse_dob: Yup.date().when("add_spouse_", {
    is: 1,
    then: Yup.date()
      .required("Your spouses date of birth is required")
      .max(moment().subtract(18, "years"), "Must be 18 and older"),
    otherwise: Yup.date().nullable(),
  }),
  amount_type: Yup.string()
    .oneOf(amountType)
    .defined("Required")
    .required("Required"),
  amount_value: Yup.number()
    .oneOf([200000, 500000, 800000])
    .required("Required")
    .min(200000, "Minimum amount is KES: 200K")
    .max(800000, "Maximum amount is KES: 800K"),
};

export const PRUMemorialHeader = () => {
  const { activeStep } = useWizard();

  return (
    <div className="steps clearfix">
      <ul id="tabControls" role="tablist">
        <li
          role="tab"
          className={`${activeStep === 0 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-0"
            aria-controls="form-quote-p-0"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Your Goals</span>
            </div>
          </a>
        </li>
        <li
          role="tab"
          className={`${activeStep === 1 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-0"
            aria-controls="form-quote-p-0"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Optional Benefits</span>
            </div>
          </a>
        </li>
        <li
          role="tab"
          className={`${activeStep === 2 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-2"
            aria-controls="form-quote-p-2"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Personal Details</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default function PRUMemorialForm(props: any) {
  const { setFieldValue, values, errors } = props;

  return (
    <section className="content">
      <div className="inner">
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <SelectField
              label="Choose your total amount of money that you want to receive from your PRUMemorial Policy"
              type="date"
              name="amount_value"
              className="form-control py-2"
              required
            >
              <option></option>
              <option value={200000}>KES 200,000</option>
              <option value={500000}>KES 500,000</option>
              <option value={800000}>KES 800,000</option>
            </SelectField>
          </div>
        </div>
        <div className="form-holder mb-3">
          <small className="d-block">
            <em className="fw-bold">NOTE</em>
          </small>
          <small className="d-block">
            <em>
              For death benefit of the policy holder and the spouse, the amount
              paid is full 100%{" "}
            </em>
          </small>
          <small className="d-block">
            <em>
              For death benefit of other family members including children, the
              amount paid is 50%
            </em>
          </small>
        </div>
        <div className="form-row mb-2">
          <div className="form-holder form-holder-2 fw-bold">
            <label>What is your date of birth?</label>
          </div>
        </div>
        <div className="form-row col-md-6 col-xs-12 col-sm-12">
          <div className="form-holder form-holder-2">
            <InputField
              label="Day"
              type="number"
              name="applicant_dob_day"
              className="form-control py-2"
              required
              min={1}
              max={moment(
                values?.applicant_dob_month || moment().month()
              ).daysInMonth()}
              onChange={(e: any) => {
                try {
                  if (
                    values?.spouse_dob_year &&
                    values?.spouse_dob_month &&
                    moment().year() - parseInt(values?.applicant_dob_year) <= 64
                  ) {
                    const dob = moment()
                      .year(parseInt(values?.applicant_dob_year))
                      .month(parseInt(values?.applicant_dob_month) - 1 || 1)
                      .date(parseInt(e.target.value));
                    setFieldValue("applicant_dob", dob.format("YYYY-MM-DD"));
                    setFieldValue("applicant_dob_day", e.target.value);
                  }
                } catch (err) {}
              }}
            />
          </div>
          <div className="form-holder form-holder-2">
            <SelectField
              name="applicant_dob_month"
              label="Month"
              onChange={(e: any) => {
                try {
                  if (
                    values?.spouse_dob_year &&
                    values?.spouse_dob_day &&
                    moment().year() - parseInt(values?.applicant_dob_year) <= 64
                  ) {
                    const dob = moment()
                      .year(parseInt(values?.applicant_dob_year))
                      .month(parseInt(e.target.value) - 1 || 1)
                      .date(parseInt(values?.applicant_dob_day));
                    setFieldValue("applicant_dob", dob.format("YYYY-MM-DD"));
                    setFieldValue("applicant_dob_month", e.target.value);
                  }
                } catch (err) {}
              }}
            >
              <option></option>
              {_.map(
                [...Array(12)].map((_, i) => 0 + i),
                (month: number) => {
                  return (
                    <option value={month + 1}>
                      {moment().month(month).format("MMMM")}
                    </option>
                  );
                }
              )}
            </SelectField>
          </div>
          <div className="form-holder form-holder-2">
            <InputField
              label="Year"
              type="number"
              name="applicant_dob_year"
              className="form-control py-2"
              required
              max={moment().subtract(18, "years").year()}
              min={moment().subtract(64, "years").year()}
              onChange={(e: any) => {
                try {
                  const dob = moment()
                    .year(parseInt(e.target.value))
                    .month(parseInt(values?.applicant_dob_month) - 1 || 1)
                    .date(parseInt(values?.applicant_dob_day));
                  setFieldValue("applicant_dob", dob.format("YYYY-MM-DD"));
                  setFieldValue("applicant_dob_year", e.target.value);
                } catch (err) {}
              }}
            />
          </div>
        </div>
        {errors?.applicant_dob && (
          <div className="invalid-feedback d-block mb-2">
            {errors?.applicant_dob}
          </div>
        )}
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <div className="form-check form-check-inline d-block">
              <label className="form-check-label fw-bold">
                <Field
                  type="checkbox"
                  className="form-check-input"
                  name="add_spouse_"
                  value="1"
                  required
                  onChange={(e: any) => {
                    setFieldValue("add_spouse_", e.target.checked ? 1 : 0);
                    if (!e.target.checked) {
                      setFieldValue("add_spouse_", undefined);
                      setFieldValue("spouse_dob", undefined);
                    }
                  }}
                  checked={values["add_spouse_"] === 1}
                />
                Add your Spouse
              </label>
            </div>
          </div>
        </div>
        {values?.add_spouse_ === 1 && (
          <>
            <div className="form-row mb-2">
              <div className="form-holder form-holder-2 fw-bold">
                <label>What is your Spouse's date of birth?</label>
              </div>
            </div>
            <div className="form-row col-md-6 col-xs-12 col-sm-12">
              <div className="form-holder form-holder-2">
                <InputField
                  label="Day"
                  type="number"
                  name="spouse_dob_day"
                  className="form-control py-2"
                  required
                  min={1}
                  max={moment(
                    values?.spouse_dob_month || moment().month()
                  ).daysInMonth()}
                  onChange={(e: any) => {
                    try {
                      if (values?.spouse_dob_year && values?.spouse_dob_month) {
                        const dob = moment()
                          .year(parseInt(values?.spouse_dob_year))
                          .month(parseInt(values?.spouse_dob_month) - 1 || 1)
                          .date(parseInt(e.target.value));
                        setFieldValue("spouse_dob", dob.format("YYYY-MM-DD"));
                        setFieldValue("spouse_dob_day", e.target.value);
                      }
                    } catch (err) {}
                  }}
                />
              </div>
              <div className="form-holder form-holder-2">
                <SelectField
                  name="spouse_dob_month"
                  label="Month"
                  onChange={(e: any) => {
                    try {
                      if (values?.spouse_dob_year && values?.spouse_dob_day) {
                        const dob = moment()
                          .year(parseInt(values?.spouse_dob_year))
                          .month(parseInt(e.target.value) - 1 || 1)
                          .date(parseInt(values?.spouse_dob_day));
                        setFieldValue("spouse_dob", dob.format("YYYY-MM-DD"));
                        setFieldValue("spouse_dob_month", e.target.value);
                      }
                    } catch (err) {}
                  }}
                >
                  <option></option>
                  {_.map(
                    [...Array(12)].map((_, i) => 0 + i),
                    (month: number) => {
                      return (
                        <option value={month + 1}>
                          {moment().month(month).format("MMMM")}
                        </option>
                      );
                    }
                  )}
                </SelectField>
              </div>
              <div className="form-holder form-holder-2">
                <InputField
                  label="Year"
                  type="number"
                  name="spouse_dob_year"
                  className="form-control py-2"
                  required
                  max={moment().subtract(18, "years").year()}
                  min={moment().subtract(64, "years").year()}
                  onChange={(e: any) => {
                    try {
                      if (values?.spouse_dob_day && values?.spouse_dob_month) {
                        const dob = moment()
                          .year(parseInt(e.target.value))
                          .month(parseInt(values?.spouse_dob_month) - 1 || 1)
                          .date(parseInt(values?.spouse_dob_day));
                        setFieldValue("spouse_dob", dob.format("YYYY-MM-DD"));
                        setFieldValue("spouse_dob_year", e.target.value);
                      }
                    } catch (err) {}
                  }}
                />
              </div>
            </div>
            {errors?.spouse_dob && (
              <div className="invalid-feedback d-block mb-2">
                {errors?.spouse_dob}
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
}
