/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { useWizard } from "react-use-wizard";
import * as Yup from "yup";
import HospiCash from "./subforms/HospiCash";

export const PRUHospiCashInitialValues = {
  hospital_cash_: 1,
};

export const PRUHospiCashValidationSchema = {
  hospital_cash_: Yup.number().nullable(),
  hospital_cash: Yup.object().when("hospital_cash_", {
    is: 1,
    then: Yup.object().shape({
      principaldob: Yup.date()
        .max(
          moment().subtract(18, "years"),
          "You must be older than 18 years old"
        )
        .min(
          moment().subtract(65, "years"),
          "You must be younger than 65 years old"
        )
        .required("Required"),
      cover_type: Yup.string().required(),
      days_exclusion: Yup.string().required(),
      principalnight_benefit: Yup.number().when("cover_type", {
        is: "extended",
        then: Yup.number()
          .required("Required")
          .min(1000000, "Minimum amount is KES: 1M")
          .max(20000000, "Maximum amount is KES: 20M"),
        otherwise: Yup.number()
          .required("Required")
          .max(30000, "Maximum amount is KES: 30,000"),
      }),
      spouse: Yup.object().shape({
        total_covered: Yup.number().nullable(),
        pernight_benefit: Yup.number().nullable(),
        spouseDOB: Yup.array(),
      }),
      children: Yup.object().shape({
        total_covered: Yup.number().nullable(),
        pernight_benefit: Yup.number().nullable(),
        childrenDOB: Yup.array(),
      }),
      parents: Yup.object().shape({
        total_covered: Yup.number().nullable(),
        pernight_benefit: Yup.number().nullable(),
        parentsDOB: Yup.array(),
      }),
      parents_inlaws: Yup.object().shape({
        total_covered: Yup.number().nullable(),
        pernight_benefit: Yup.number().nullable(),
        parents_inlawsDOB: Yup.array(),
      }),
    }),
    otherwise: Yup.object().nullable(),
  }),
};

export const PRUHospiCashHeader = (props: any) => {
  const { activeStep } = useWizard();

  return (
    <div className="steps clearfix">
      <ul id="tabControls" role="tablist">
        <li
          role="tab"
          className={`${activeStep === 0 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-0"
            aria-controls="form-quote-p-0"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Your Goals</span>
            </div>
          </a>
        </li>
        <li
          role="tab"
          className={`${activeStep === 1 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-2"
            aria-controls="form-quote-p-2"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Personal Details</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default function PRUHospiCashForm(props: any) {
  return (
    <section className="content">
      <div className="inner">
        <HospiCash {...props} isSingleProduct={true} />
      </div>
    </section>
  );
}
