/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field } from "formik";
import _ from "lodash";
import moment from "moment";
import { useWizard } from "react-use-wizard";
import * as Yup from "yup";
import { InputField } from "../components/InputField";
import { SelectField } from "../components/SelectField";
import HospiCash from "./subforms/HospiCash";

const paymentFrequency = ["monthly", "quarterly", "half-yearly", "yearly"];

const amountType = ["affordable-premium", "target-amount"];

export const PRUSelectInitialValues = {
  amount_type: "target-amount",
  ci: true,
  tpd: true,
};

export const PRUSelectValidationSchema = {
  premium_term: Yup.number()
    .required("Required")
    .min(10, "Minimum is 10 years")
    .max(20, "Maximum is 20 years"),
  payouts: Yup.number()
    .required("Required")
    .min(1, "Minimum is 1 payout")
    .max(9, "Maximum is 9 payouts"),
  applicant_dob: Yup.date()
    .required()
    .max(moment().subtract(18, "years"), "You must be older than 18 years old")
    .required("Required"),
  payment_frequency: Yup.mixed()
    .oneOf(paymentFrequency)
    .defined("Required")
    .required("Required"),
  amount_type: Yup.string()
    .oneOf(amountType)
    .defined("Required")
    .required("Required"),
  amount_value: Yup.number()
    .required("Required")
    .min(1000000, "Minimum amount is KES: 1M")
    .max(80000000, "Maximum amount is KES: 80M"),
};

export const PRUSelectHeader = (props: any) => {
  const { activeStep } = useWizard();

  return (
    <div className="steps clearfix">
      <ul id="tabControls" role="tablist">
        <li
          role="tab"
          className={`${activeStep === 0 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-0"
            aria-controls="form-quote-p-0"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Your Goals</span>
            </div>
          </a>
        </li>
        <li
          role="tab"
          className={`${activeStep === 1 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-0"
            aria-controls="form-quote-p-0"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Optional Benefits</span>
            </div>
          </a>
        </li>
        <li
          role="tab"
          className={`${activeStep === 2 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-2"
            aria-controls="form-quote-p-2"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Personal Details</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default function PRUSelectForm(props: any) {
  return (
    <section className="content">
      <div className="inner">
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <InputField
              label="What is your Date of Birth?"
              type="date"
              name="applicant_dob"
              className="form-control py-2"
              required
              max={moment()
                .subtract(18, "years")
                .subtract(1, "week")
                .format("YYYY-MM-DD")}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <InputField
              label="What total amount of money do you want to receive from your PRUSelect Policy to help you achieve your goal?"
              type="number"
              name="amount_value"
              className="form-control py-2"
              required
              min="1000000"
              max="80000000"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-holder">
            <SelectField
              name="premium_term"
              className="form-select py-2"
              required
              label="How long do you want your policy to be?"
            >
              <option></option>
              {_.map(
                [...Array(11)].map((_, i) => 10 + i),
                (term: number) => {
                  return <option value={term}>{term} years</option>;
                }
              )}
            </SelectField>
          </div>
          <div className="form-holder">
            <SelectField
              name="payment_frequency"
              className="form-select py-2"
              required
              label="How frequently do you want to make payments?"
            >
              <option></option>
              {paymentFrequency.map((pf) => (
                <option value={pf}>{_.startCase(pf)}</option>
              ))}
            </SelectField>
          </div>
        </div>
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <SelectField
              name="payouts"
              className="form-select py-2"
              required
              label="How frequent would you want to be receiving your money during the policy?"
            >
              <option></option>
              <option value={1}>1 payout</option>
              {_.map(
                [...Array(8)].map((_, i) => 2 + i),
                (payout: number) => {
                  return <option value={payout}>{payout} payouts</option>;
                }
              )}
            </SelectField>
          </div>
        </div>
      </div>
    </section>
  );
}

export const PRUSelectBenefitsValidationSchema = {
  ci: Yup.boolean().required("Required"),
  tpd: Yup.boolean().required("Required"),
  hospital_cash_: Yup.number().nullable(),
  hospital_cash: Yup.object().when("hospital_cash_", {
    is: 1,
    then: Yup.object().shape({
      cover_type: Yup.string().required(),
      days_exclusion: Yup.string().required(),
      principalnight_benefit: Yup.number().when("cover_type", {
        is: "extended",
        then: Yup.number()
          .required("Required")
          .min(1000000, "Minimum amount is KES: 1M")
          .max(20000000, "Maximum amount is KES: 20M"),
        otherwise: Yup.number()
          .required("Required")
          .max(30000, "Maximum amount is KES: 30,000"),
      }),
      spouse: Yup.object().shape({
        total_covered: Yup.number().nullable(),
        pernight_benefit: Yup.number().nullable(),
        spouseDOB: Yup.array(),
      }),
      children: Yup.object().shape({
        total_covered: Yup.number().nullable(),
        pernight_benefit: Yup.number().nullable(),
        childrenDOB: Yup.array(),
      }),
      parents: Yup.object().shape({
        total_covered: Yup.number().nullable(),
        pernight_benefit: Yup.number().nullable(),
        parentsDOB: Yup.array(),
      }),
      parents_inlaws: Yup.object().shape({
        total_covered: Yup.number().nullable(),
        pernight_benefit: Yup.number().nullable(),
        parents_inlawsDOB: Yup.array(),
      }),
    }),
    otherwise: Yup.object().nullable(),
  }),
};

export const PRUSelectBenefits = (props: any) => {
  const { setFieldValue, values } = props;

  return (
    <section className="content">
      <div className="inner">
        <div className="alert alert-warning" role="alert">
          These additional benefits attract an extra cost
        </div>
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <div className="form-check form-check-inline d-block">
              <label className="form-check-label">
                <Field
                  type="checkbox"
                  className="form-check-input"
                  name="ci"
                  value={values["ci"]}
                  required
                  onChange={(e: any) => {
                    setFieldValue("ci", e.target.checked ? true : false);
                  }}
                  checked={values["ci"]}
                />
                Critical illness benefit
              </label>
            </div>
            <small>
              (A lumpsum amount of money that you will be paid for any first
              time diagnosis of a critical illness during the policy)
            </small>
          </div>
        </div>
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <div className="form-check form-check-inline d-block">
              <Field
                type="checkbox"
                className="form-check-input"
                name="tpd"
                value={values["tpd"]}
                required
                onChange={(e: any) => {
                  setFieldValue("tpd", e.target.checked ? true : false);
                }}
                checked={values["tpd"]}
              />
              <label className="form-check-label">
                {" "}
                Total Permanent Disability
              </label>
            </div>
            <small>
              (In case of disability of the policy holder, future payments by
              the customer are waived off but you will still receive all your
              money according to the plan)
            </small>
          </div>
        </div>

        <HospiCash {...props} />
      </div>
    </section>
  );
};
