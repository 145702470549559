export function Modal({ ...props }: any) {
  const { isOpened, handleClose, handleSubmit, title, canSubmit, readOnly } =
    props;

  return (
    <div
      className={`modal fade ${isOpened && "show"}`}
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdropLabel"
      aria-hidden={isOpened ? "false" : "true"}
      style={{ display: isOpened ? "block" : "none" }}
    >
      <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {title}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">{props.children}</div>
          <div className="modal-footer">
            {!readOnly && (
              <button
                type="button"
                className="btn btn-primary btn-outline"
                disabled={!canSubmit}
                onClick={handleSubmit}
              >
                Update
              </button>
            )}
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
