import { Field } from "formik";
import * as Yup from "yup";
import { InputField } from "../components/InputField";
import HospiCash from "./subforms/HospiCash";

export const BenefitsValidationSchema = {
  ci: Yup.number().nullable(),
  ci_amount: Yup.number().when("ci", {
    is: 1,
    then: Yup.number()
      .required("Required")
      .min(500000, "Minimum amount is KES: 0.5M")
      .max(15000000, "Maximum amount is KES: 15M"),
    otherwise: Yup.number().nullable(),
  }),
  accdeath: Yup.number().nullable(),
  accdeath_amount: Yup.number().when("accdeath", {
    is: 1,
    then: Yup.number()
      .required("Required")
      .min(1000000, "Minimum amount is KES: 1M")
      .max(20000000, "Maximum amount is KES: 20M"),
    otherwise: Yup.number().nullable(),
  }),
  hospital_cash_: Yup.number().nullable(),
  hospital_cash: Yup.object().when("hospital_cash_", {
    is: 1,
    then: Yup.object().shape({
      cover_type: Yup.string().required(),
      days_exclusion: Yup.string().required(),
      principalnight_benefit: Yup.number().when("cover_type", {
        is: "extended",
        then: Yup.number()
          .required("Required")
          .min(1000000, "Minimum amount is KES: 1M")
          .max(20000000, "Maximum amount is KES: 20M"),
        otherwise: Yup.number()
          .required("Required")
          .max(30000, "Maximum amount is KES: 30,000"),
      }),
      spouse: Yup.object().shape({
        total_covered: Yup.number().nullable(),
        pernight_benefit: Yup.number().nullable(),
        spouseDOB: Yup.array(),
      }),
      children: Yup.object().shape({
        total_covered: Yup.number().nullable(),
        pernight_benefit: Yup.number().nullable(),
        childrenDOB: Yup.array(),
      }),
      parents: Yup.object().shape({
        total_covered: Yup.number().nullable(),
        pernight_benefit: Yup.number().nullable(),
        parentsDOB: Yup.array(),
      }),
      parents_inlaws: Yup.object().shape({
        total_covered: Yup.number().nullable(),
        pernight_benefit: Yup.number().nullable(),
        parents_inlawsDOB: Yup.array(),
      }),
    }),
    otherwise: Yup.object().nullable(),
  }),
};

export default function Benefits(props: any) {
  const { setFieldValue, values, tpd } = props;

  return (
    <section className="content">
      <div className="inner">
        <div className="alert alert-warning" role="alert">
          These additional benefits attract an extra cost
        </div>
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <div className="form-check form-check-inline d-block">
              <label className="form-check-label">
                <Field
                  type="checkbox"
                  className="form-check-input"
                  name="ci"
                  value="1"
                  required
                  onChange={(e: any) => {
                    setFieldValue("ci", e.target.checked ? 1 : 0);
                    if (!e.target.checked) {
                      setFieldValue("ci_amount", undefined);
                    }
                  }}
                  checked={values["ci"] === 1}
                />
                Critical illness benefit
              </label>
            </div>
            <small>
              (A lumpsum amount of money that you will be paid for any first
              time diagnosis of a critical illness during the policy)
            </small>
          </div>
        </div>
        {values?.ci === 1 && (
          <div className="form-row">
            <div className="form-holder form-holder-2">
              <InputField
                label="Cover Sum Assured (KES)"
                type="number"
                name="ci_amount"
                className="form-control py-2"
                required
                min="500000"
                max="15000000"
              />
            </div>
          </div>
        )}
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <div className="form-check form-check-inline d-block">
              <label className="form-check-label">
                <Field
                  type="checkbox"
                  className="form-check-input"
                  name="accdeath"
                  value="1"
                  required
                  onChange={(e: any) => {
                    setFieldValue("accdeath", e.target.checked ? 1 : 0);
                    if (!e.target.checked) {
                      setFieldValue("accdeath_amount", undefined);
                    }
                  }}
                  checked={values["accdeath"] === 1}
                />
                Accidental death benefit
              </label>
            </div>
            <small>
              (A lumpsum amount of money that your family will be paid in the
              event of your death during the policy)
            </small>
          </div>
        </div>
        {values?.accdeath === 1 && (
          <div className="form-row">
            <div className="form-holder form-holder-2">
              <InputField
                label="Cover Sum Assured (KES)"
                type="number"
                name="accdeath_amount"
                className="form-control py-2"
                required
                min="1000000"
                max="20000000"
              />
            </div>
          </div>
        )}
        {tpd && (
          <div className="form-row">
            <div className="form-holder form-holder-2">
              <div className="form-check form-check-inline d-block">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="tpd"
                />
                <label className="form-check-label">
                  {" "}
                  Total Permanent Disability
                </label>
              </div>
              <small>
                (In case of disability of the policy holder, future payments by
                the customer are waived off but you will still receive all your
                money according to the plan)
              </small>
            </div>
          </div>
        )}
        <HospiCash {...props} />
      </div>
    </section >
  );
}
