/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field } from "formik";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { useWizard } from "react-use-wizard";
import * as Yup from "yup";
import { InputField } from "../components/InputField";
import { SelectField } from "../components/SelectField";

const paymentFrequency = ["monthly", "quarterly", "half-yearly", "yearly"];

const amountType = ["affordable-premium", "target-amount"];

export const PRUCriticalCareInitialValues = {
  status: "non-smoker",
  gender: "male",
  amount_type: "target-amount",
  application_type: "single",
  death_rider: true,
};

export const PRUCriticalCareValidationSchema = {
  premium_term: Yup.number().required("Required"),
  applicant_dob: Yup.date()
    .required()
    .max(moment().subtract(18, "years"), "You must be older than 18 years old")
    .required("Required"),
  gender: Yup.string(),
  status: Yup.string().required("Required"),
  payment_frequency: Yup.mixed()
    .oneOf(paymentFrequency)
    .defined("Required")
    .required("Required"),
  amount_type: Yup.string()
    .oneOf(amountType)
    .defined("Required")
    .required("Required"),
  amount_value: Yup.number()
    .required("Required")
    .min(1000000, "Minimum amount is KES: 1M")
    .max(80000000, "Maximum amount is KES: 80M"),
};

export const PRUCriticalCareHeader = (props: any) => {
  const { activeStep } = useWizard();

  return (
    <div className="steps clearfix">
      <ul id="tabControls" role="tablist">
        <li
          role="tab"
          className={`${activeStep === 0 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-0"
            aria-controls="form-quote-p-0"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Your Goals</span>
            </div>
          </a>
        </li>
        <li
          role="tab"
          className={`${activeStep === 1 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-0"
            aria-controls="form-quote-p-0"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Optional Benefits</span>
            </div>
          </a>
        </li>
        <li
          role="tab"
          className={`${activeStep === 2 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-2"
            aria-controls="form-quote-p-2"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Personal Details</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default function PRUCriticalCareForm(props: any) {
  return (
    <section className="content">
      <div className="inner">
        <div className="form-row">
          <div className="form-holder">
            <InputField
              label="What is your Date of Birth?"
              type="date"
              name="applicant_dob"
              className="form-control py-2"
              required
              max={moment()
                .subtract(18, "years")
                .subtract(1, "week")
                .format("YYYY-MM-DD")}
            />
          </div>
          <div className="form-holder">
            <SelectField name="status" label="Are you a smoker?">
              <option></option>
              <option key="non-smoker" value="non-smoker">
                No - Non-Smoker
              </option>
              <option key="smoker" value="smoker">
                Yes - Smoker
              </option>
            </SelectField>
          </div>
        </div>
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <InputField
              label="What total amount of money do you want to receive from your PRUCritical Care Policy to help you achieve your goal?"
              type="number"
              name="amount_value"
              className="form-control py-2"
              required
              min="1000000"
              max="80000000"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-holder">
            <SelectField
              name="premium_term"
              className="form-select py-2"
              required
              label="How long do you want your policy to be?"
            >
              <option></option>
              {_.map(
                [...Array(11)].map((_, i) => 10 + i),
                (term: number) => {
                  return <option value={term}>{term} years</option>;
                }
              )}
            </SelectField>
          </div>
          <div className="form-holder">
            <SelectField
              name="payment_frequency"
              className="form-select py-2"
              required
              label="How frequently do you want to make payments?"
            >
              <option></option>
              {paymentFrequency.map((pf) => (
                <option value={pf}>{_.startCase(pf)}</option>
              ))}
            </SelectField>
          </div>
        </div>
      </div>
    </section>
  );
}

export const PRUCriticalCareBenefitsValidationSchema = {
  death_rider: Yup.boolean().required("Required"),
};

export const PRUCriticalCareBenefits = (props: any) => {
  const { setFieldValue, values } = props;

  return (
    <section className="content">
      <div className="inner">
        <div className="alert alert-warning" role="alert">
          These additional benefits attract an extra cost
        </div>
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <div className="form-check form-check-inline d-block">
              <label className="form-check-label">
                <Field
                  type="checkbox"
                  className="form-check-input"
                  name="ci"
                  value="1"
                  required
                  onChange={(e: any) => {
                    setFieldValue(
                      "death_rider",
                      e.target.checked ? true : false
                    );
                    if (!e.target.checked) {
                      setFieldValue("death_rider", false);
                    }
                  }}
                  checked={values["death_rider"]}
                />
                Death Benefit
              </label>
            </div>
            <small>
              (A lumpsum amount of money that will be paid to your loved ones
              and beneficiaries, in the event of your passing on due to natural
              causes, accidents, or an illness)
            </small>
          </div>
        </div>
      </div>
    </section>
  );
};
