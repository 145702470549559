import { InputField } from "../components/InputField";
import * as Yup from "yup";

export const PersonalDetailsValidationSchema = {
  client_name: Yup.string().required("Whats your full name?"),
  client_email: Yup.string()
    .email("Enter a valid email address")
    .required("What's your email address?"),
  client_phone_number: Yup.string().required("What's your phone number?"),

};

export default function PersonalDetails(props: any) {
  const { children } = props;
  return (
    <section className="content">
      <div className="inner">
        <div className="form-row">
          <div className="form-holder form-holder-2">
            <InputField
              label="Full Name"
              type="text"
              name="client_name"
              className="form-control py-2"
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-holder form-holder-3">
            <InputField
              label="Email Address"
              type="email"
              name="client_email"
              className="form-control py-2"
              required
            />
          </div>
          <div className="form-holder">
            <InputField
              label="Phone Number"
              type="tel"
              name="client_phone_number"
              className="form-control py-2"
              required
            />
          </div>
        </div>
        {children}
      </div>
    </section>
  );
}
