import { Formik, FormikProps } from "formik";
import _ from "lodash";
import { FormEvent, useEffect, useState } from "react";
import { useWizard, Wizard } from "react-use-wizard";
import * as Yup from "yup";
import "./App.css";
import { SelectField } from "./components/SelectField";
import PersonalDetails, {
  PersonalDetailsValidationSchema,
} from "./forms/PersonalDetails";
import PRULifeForm, {
  PRULifeHeader,
  PRULifeInitialValues,
  PRULifeValidationSchema,
} from "./forms/PRULife";
import PRUPensionForm, {
  PRUPensionHeader,
  PRUPensionInitialValues,
  PRUPensionValidationSchema,
} from "./forms/PRUPension";

import { AxiosError, AxiosResponse } from "axios";
import Benefits, { BenefitsValidationSchema } from "./forms/GlobalBenefits";
import PRUCriticalCareForm, {
  PRUCriticalCareBenefits,
  PRUCriticalCareBenefitsValidationSchema,
  PRUCriticalCareHeader,
  PRUCriticalCareInitialValues,
  PRUCriticalCareValidationSchema,
} from "./forms/PRUCriticalCare";
import PRUMemorialForm, {
  PRUMemorialHeader,
  PRUMemorialInitialValues,
  PRUMemorialValidationSchema,
} from "./forms/PRUMemorial";
import PRUSelectForm, {
  PRUSelectBenefits,
  PRUSelectBenefitsValidationSchema,
  PRUSelectHeader,
  PRUSelectInitialValues,
  PRUSelectValidationSchema,
} from "./forms/PRUSelect";
import PRUTermForm, {
  PRUTermHeader,
  PRUTermInitialValues,
  PRUTermValidationSchema,
} from "./forms/PRUTerm";
import { camelizeKeys, currencyFormatter, performRequest } from "./lib";
import PRUDahariDumuForm, {
  PRUDahariDumuHeader,
  PRUDahariDumuValidationSchema,
  PRUDahariDumuBenefitsValidationSchema,
  PRUDahariDumuInitialValues,
  PRUDahariDumuBenefits,
} from "./forms/PRUDahariDumu";
import PRUHospiCashForm, {
  PRUHospiCashHeader,
  PRUHospiCashValidationSchema,
  PRUHospiCashInitialValues,
} from "./forms/PRUHospiCash";

enum PRURequestTypes {
  CALCULATED_FIGURES = "calculated-figures",
  PRODUCT_QUOTE = "product-quote",
  EMAIL_QUOTE = "email-quote",
}

const WizardHeader = () => {
  const { activeStep, goToStep } = useWizard();

  return (
    <div className="steps clearfix">
      <ul id="tabControls" role="tablist">
        <li
          role="tab"
          className={`${activeStep === 0 ? "current" : "disabled"}`}
          aria-disabled={activeStep === 0 ? false : true}
          aria-selected={activeStep === 0 ? true : false}
        >
          <button
            id="form-quote-t-0"
            aria-controls="form-quote-p-0"
            onClick={(e) => goToStep(0)}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Your Goals</span>
            </div>
          </button>
        </li>
        <li
          role="tab"
          className={`${activeStep === 1 ? "current" : "disabled"}`}
        >
          <button
            id="form-quote-t-1"
            aria-controls="form-quote-p-1"
            onClick={(e) => goToStep(1)}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Optional Benefits</span>
            </div>
          </button>
        </li>
        <li
          role="tab"
          className={`${activeStep === 2 ? "current" : "disabled"}`}
        >
          <button
            id="form-quote-t-2"
            aria-controls="form-quote-p-2"
            onClick={(e) => goToStep(2)}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Personal Details</span>
            </div>
          </button>
        </li>
      </ul>
    </div>
  );
};

const WizardFooter = (props: any) => {
  const { previousStep, activeStep, isLastStep, isFirstStep, nextStep } =
    useWizard();
  const [fetching, setFetching] = useState(false);

  const {
    validateForm,
    isValid,
    handleSubmit,
    setCurrentStep,
    intermediateFetch,
    calculatedValues,
    isFetching,
    requestError,
    values,
  } = props;

  const handleNextStep = () => {
    validateForm().then((e: any) => {
      validateForm().then((e: any) => {
        if (isValid) {
          intermediateFetch();
          setCurrentStep(activeStep + 1);
          nextStep();
        }
      });
    });
  };

  const handlePrevStep = () => {
    setCurrentStep(activeStep - 1);
    previousStep();
  };

  useEffect(() => {
    if (isValid && !calculatedValues && !isFetching && !fetching) {
      setFetching(true);
      intermediateFetch();
    }
  }, [
    isValid,
    calculatedValues,
    isFetching,
    intermediateFetch,
    fetching,
    setFetching,
  ]);

  return (
    <>
      <hr />
      {isValid && (
        <section className="content" style={{ padding: "0px 45px 0px 45px" }}>
          <div className="inner">
            <label
              htmlFor=""
              className="text-right text-danger fw-bold d-block h5"
            >
              {isFetching && !requestError ? (
                <span className="fw-light">
                  <div className="spinner-grow spinner-grow-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </span>
              ) : (
                <>
                  {values?.pension_projection
                    ? "Annual Retirement Income: "
                    : "Annual Premium: "}
                  {calculatedValues?.totalAnnualPremium ||
                  calculatedValues?.totalPayable ||
                  calculatedValues?.monthlyRetirementIncome ? (
                    currencyFormatter
                      .format(
                        calculatedValues?.totalAnnualPremium ||
                          calculatedValues?.totalPayable ||
                          calculatedValues?.monthlyRetirementIncome
                      )
                      .toString()
                  ) : (
                    <div className="spinner-grow spinner-grow-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </>
              )}
            </label>
          </div>
        </section>
      )}
      <hr />
      <div className="actions clearfix">
        <ul role="menu" aria-label="Pagination">
          <li>
            {!isFirstStep && (
              <button
                type="button"
                className="btn btn-outline-secondary mt-2 btn-back fw-bold px-5 py-2"
                onClick={() => handlePrevStep()}
                disabled={isFirstStep}
              >
                Back
              </button>
            )}
          </li>
          {!isLastStep && (
            <li>
              <button
                type="button"
                className="btn btn-outline-danger mt-2 btn-next fw-bold px-5 py-2"
                onClick={() => handleNextStep()}
                disabled={!isValid || isLastStep}
              >
                Next
              </button>
            </li>
          )}
          {isLastStep && (
            <li>
              <button
                type="submit"
                className="btn btn-danger mt-2 btn-next fw-bold px-5 py-2"
                disabled={!isValid}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                Show Me My Quote
              </button>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

interface PremiumProps {
  sumAssured: number;
  basicPremium: number;
  ciPremium?: number;
  ciSumAssured?: number;
  pcf?: number;
  policyFee?: number;
  totalPayable: number;
  tpdPremium?: number;
  tpdSumAssured?: number;
  paymentFrequency?: string;
  totalAccumulated?: number;
  monthlyRetirementIncome?: number;
  totalAnnualPremium?: number;
}

function App(props: { product: string }) {
  const { product } = props;

  const [currentStep, setCurrentStep] = useState(0);

  const [intermediatePremium, setIntermediatePremium] =
    useState<PremiumProps>();
  const [requestError, setRequestError] = useState("");
  const [responseErrors, setResponseErrors] = useState([]);
  const [fetching, setFetching] = useState(false);

  const [emailQuote, toggleEmailQuote] = useState(false);

  const productComponent: any = {
    prupension: {
      header: PRUPensionHeader,
      form: PRUPensionForm,
      validation: [PRUPensionValidationSchema, PersonalDetailsValidationSchema],
      initialValues: PRUPensionInitialValues,
      benefits: false,
    },
    prulife: {
      header: PRULifeHeader,
      form: PRULifeForm,
      validation: [
        PRULifeValidationSchema,
        BenefitsValidationSchema,
        {
          ...PersonalDetailsValidationSchema,
          applicant: Yup.object().shape({
            gender: Yup.string().required("Required"),
          }),
        },
      ],
      initialValues: PRULifeInitialValues,
      benefits: Benefits,
    },
    pruselect: {
      header: PRUSelectHeader,
      form: PRUSelectForm,
      validation: [
        PRUSelectValidationSchema,
        PRUSelectBenefitsValidationSchema,
        PersonalDetailsValidationSchema,
      ],
      initialValues: PRUSelectInitialValues,
      benefits: PRUSelectBenefits,
    },
    pruterm: {
      header: PRUTermHeader,
      form: PRUTermForm,
      validation: [
        PRUTermValidationSchema,
        PRUSelectBenefitsValidationSchema,
        PersonalDetailsValidationSchema,
      ],
      initialValues: PRUTermInitialValues,
      benefits: PRUSelectBenefits,
    },
    prumemorial: {
      header: PRUMemorialHeader,
      form: PRUMemorialForm,
      validation: [
        PRUMemorialValidationSchema,
        PRUSelectBenefitsValidationSchema,
        PersonalDetailsValidationSchema,
      ],
      initialValues: PRUMemorialInitialValues,
      benefits: PRUSelectBenefits,
    },
    prucriticalcare: {
      header: PRUCriticalCareHeader,
      form: PRUCriticalCareForm,
      validation: [
        PRUCriticalCareValidationSchema,
        PRUCriticalCareBenefitsValidationSchema,
        PersonalDetailsValidationSchema,
      ],
      initialValues: PRUCriticalCareInitialValues,
      benefits: PRUCriticalCareBenefits,
    },
    prudaharidumu: {
      header: PRUDahariDumuHeader,
      form: PRUDahariDumuForm,
      validation: [
        PRUDahariDumuValidationSchema,
        PRUDahariDumuBenefitsValidationSchema,
        PersonalDetailsValidationSchema,
      ],
      initialValues: PRUDahariDumuInitialValues,
      benefits: PRUDahariDumuBenefits,
    },
    pruhospicash: {
      header: PRUHospiCashHeader,
      form: PRUHospiCashForm,
      validation: [
        PRUHospiCashValidationSchema,
        PersonalDetailsValidationSchema,
      ],
      initialValues: PRUHospiCashInitialValues,
      benefits: false,
    },
  };

  const ComponentHeader: any =
    productComponent[product]?.header || WizardHeader;
  const ComponentForm: any = productComponent[product].form;
  const ComponentBenefits: any = productComponent[product].benefits;

  const fetchIntermediatePremium = (data: any) => {
    setFetching(true);
    const postData: any = JSON.stringify({ ...data }, null, " ");
    const pathname = ["prupension", "pruhospicash"].includes(product)
      ? ""
      : product;
    const submitData = performRequest(
      `${process.env.REACT_APP_API_BASE_URL}${pathname}`,
      postData,
      "POST",
      {
        email: process.env.REACT_APP_API_EMAIL,
        "pass-Key": `${process.env.REACT_APP_PASS_KEY}`,
      }
    );
    setIntermediatePremium(undefined);
    submitData
      .then(function (response: AxiosResponse) {
        setFetching(false);
        if (response.status >= 200 && response.status < 400) {
          setIntermediatePremium(camelizeKeys(response.data?.calcutatedValues));
        } else {
          setResponseErrors(response?.data?.errors);
          setRequestError(response?.data?.message);
          alert(response?.data?.message);
        }
      })
      .catch(function (error: AxiosError) {
        setFetching(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log("response", error.response.data);
          console.log("response", error.response.status);
          console.log("response", error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log("request", error);
          setRequestError("There is an issue connecting to the server.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          setRequestError("There is an issue connecting to the server.");
        }
        console.log(error.config);
      })
      .finally(function () {
        setFetching(false);
      });
  };

  const sendEmailQuote = (data: any) => {
    let postValues: any = data;
    postValues = {
      ...postValues,
      request_type: PRURequestTypes.EMAIL_QUOTE,
    };
    const postData: any = JSON.stringify({ ...postValues }, null, " ");
    const pathname = ["prupension", "pruhospicash"].includes(product)
      ? ""
      : product;
    const submitData = performRequest(
      `${process.env.REACT_APP_API_BASE_URL}${pathname}`,
      postData,
      "POST",
      {
        email: "kinyua@themahoganygroup.co.ke",
        "pass-Key": `${process.env.REACT_APP_PASS_KEY}`,
      }
    );

    submitData
      .then(function (response: any) {
        if (response?.status === 200) {
          toggleEmailQuote(true);
        }
      })
      .catch(function (error: any) {
        console.error(error);
      });
  };

  return (
    <div className="App">
      <section className="py-md-5 py-0" id="quoteContainer">
        <div className="container position-relative">
          <div className="bg-grey" style={{ height: "65%" }}></div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 mb-3 mt-3 text-center text-md-start mt-md-0">
                <h1 className="mb-3 d-block">
                  <span className="fw-bold">Get a</span>{" "}
                  <span className="fw-light">Quote</span>
                </h1>
              </div>
            </div>

            <div className="wizard-v5-content w-100 bg-white">
              <div className="wizard-form">
                <Formik
                  initialValues={{
                    request_type: PRURequestTypes.CALCULATED_FIGURES,
                    ...productComponent[product].initialValues,
                  }}
                  onSubmit={(values, formikBag) => {
                    formikBag.setSubmitting(true);

                    let postValues: any = values;
                    postValues = {
                      ...postValues,
                      request_type: PRURequestTypes.CALCULATED_FIGURES,
                    };
                    const postData: any = JSON.stringify(
                      { ...postValues },
                      null,
                      " "
                    );
                    const pathname = ["prupension", "pruhospicash"].includes(
                      product
                    )
                      ? ""
                      : product;
                    const submitData = performRequest(
                      `${process.env.REACT_APP_API_BASE_URL}${pathname}`,
                      postData,
                      "POST",
                      {
                        email: "kinyua@themahoganygroup.co.ke",
                        "pass-Key": `${process.env.REACT_APP_PASS_KEY}`,
                      }
                    );

                    submitData
                      .then(function (response: any) {
                        if (response?.status === 200) {
                          setIntermediatePremium(
                            response?.data?.calcutatedValues
                          );
                          // sendEmailQuote(values);
                        }
                        formikBag.setSubmitting(false);
                      })
                      .catch(function (error: any) {
                        formikBag.setSubmitting(false);
                      });
                  }}
                  validationSchema={Yup.object().shape(
                    productComponent[product].validation[currentStep]
                  )}
                  validateOnBlur={true}
                  validateOnChange={true}
                  validateOnMount={true}
                  isInitialValid={false}
                  initialStatus={PRURequestTypes.CALCULATED_FIGURES}
                >
                  {(props: FormikProps<any>) => {
                    const {
                      values,
                      isValid,
                      isValidating,
                      isSubmitting,
                      status,
                      setSubmitting,
                      submitForm,
                    } = props;

                    if (
                      !isSubmitting &&
                      !fetching &&
                      !isValidating &&
                      isValid &&
                      status === PRURequestTypes.CALCULATED_FIGURES
                    ) {
                      console.log(values);
                    }

                    return (
                      <>
                        <form
                          className="form-quote needs-validation"
                          id="form-quote"
                          method="POST"
                          onChange={async (event: FormEvent) => {
                            if (isValid && !fetching && !isValidating) {
                              setFetching(true);
                              // setTimeout(
                              //   () => fetchIntermediatePremium(values),
                              //   4000
                              // );
                              // await new Promise((resolve) =>
                              //   setTimeout(resolve, 4000)
                              // );
                              // fetchIntermediatePremium(values);
                            }
                          }}
                        >
                          {!emailQuote ? (
                            <Wizard
                              startIndex={0}
                              header={<ComponentHeader />}
                              footer={
                                isSubmitting ? (
                                  <></>
                                ) : (
                                  <WizardFooter
                                    {...props}
                                    setCurrentStep={setCurrentStep}
                                    intermediateFetch={() => {
                                      setFetching(true);
                                      fetchIntermediatePremium(values);
                                    }}
                                    isFetching={fetching}
                                    calculatedValues={intermediatePremium}
                                    requestError={requestError}
                                  />
                                )
                              }
                            >
                              <ComponentForm {...props} />
                              {ComponentBenefits && (
                                <ComponentBenefits {...props} />
                              )}
                              {isSubmitting ? (
                                <section className="content">
                                  <div
                                    className="inner"
                                    style={{
                                      textAlign: "center",
                                      padding: "7rem 0 10rem",
                                    }}
                                  >
                                    <div className="lds-roller">
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                    <br />
                                    <br />
                                    <b>Sending your quote ...</b>
                                  </div>
                                </section>
                              ) : (
                                <PersonalDetails>
                                  {product !== "pruselect" && (
                                    <div className="form-row">
                                      <div className="form-holder form-holder-2">
                                        <SelectField
                                          name="applicant[gender]"
                                          label="Gender"
                                        >
                                          <option key="male" value="male">
                                            Male
                                          </option>
                                          <option key="female" value="female">
                                            Female
                                          </option>
                                        </SelectField>
                                      </div>
                                    </div>
                                  )}
                                </PersonalDetails>
                              )}
                            </Wizard>
                          ) : (
                            <section id="results" className="content">
                              <div className="inner pt-5">
                                <h4 className="fs-2 text-center">
                                  <span className="text-danger fw-bold">
                                    PRU
                                  </span>
                                  {_.startCase(_.replace(product, "pru", ""))}{" "}
                                  Quote
                                </h4>
                                <p className="text-center fs-4 mb-3 d-block mt-3">
                                  {values?.client_name}, a detailed quote has
                                  been sent to {values?.client_email}
                                </p>
                                <p className="text-center fs-6 mb-0">
                                  Below is an overview of your premium and
                                  payment frequency
                                </p>
                                {!_.isEmpty(intermediatePremium) && (
                                  <>
                                    <div className="text-center">
                                      <p className="fs-1 mb-0 pb-0">
                                        {currencyFormatter
                                          .format(
                                            intermediatePremium?.totalPayable ||
                                              intermediatePremium?.totalAccumulated ||
                                              intermediatePremium?.totalAnnualPremium ||
                                              0
                                          )
                                          .toString()}
                                      </p>
                                      <p className="mb-2 d-block fs-5 fw-light text-danger">
                                        {["prupension"].includes(product)
                                          ? "Accumulated Fund:"
                                          : "Total Payable Premium"}
                                      </p>
                                    </div>
                                    <div className="text-center">
                                      <p className="fs-3 mb-0 pb-0">
                                        {["prupension"].includes(product)
                                          ? currencyFormatter
                                              .format(
                                                intermediatePremium?.monthlyRetirementIncome ||
                                                  0
                                              )
                                              .toString()
                                          : _.startCase(
                                              intermediatePremium?.paymentFrequency
                                            )}
                                      </p>
                                      <p className="mb-4 d-block fs-5 fw-light text-danger">
                                        {["prupension"].includes(product)
                                          ? "Estimated retirement monthly income"
                                          : "Payment Frequency"}
                                      </p>
                                    </div>
                                  </>
                                )}
                                {/* <ul className="list-group">
                                  {!_.isEmpty(intermediatePremium) && Object.entries(intermediatePremium || {}).map((prem: any) => (
                                    <li className="list-group-item d-flex justify-content-between align-items-start">
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold">{prem[0]}</div>
                                        <span>{_.isNumber(prem[1]) ? currencyFormatter
                                          .format(prem[1])
                                          .toString() : _.startCase(prem[1])}</span>
                                      </div>
                                    </li>
                                  ))}
                                </ul> */}
                                <br />
                                <p className="text-center">
                                  <button
                                    type="submit"
                                    className="btn btn-danger mt-2 btn-next fw-bold px-5 py-2"
                                    disabled={!isValid}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.location.reload();
                                    }}
                                  >
                                    Request Another Quote
                                  </button>
                                </p>
                                <br />
                              </div>
                            </section>
                          )}
                        </form>
                        {responseErrors && responseErrors.length > 0 && (
                          <div
                            className="modal fade show"
                            id="exampleModalLive"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLiveLabel"
                            aria-modal="true"
                            role="dialog"
                            style={{ display: "block" }}
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLiveLabel"
                                  >
                                    {requestError}
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <ul className="list-group">
                                    {responseErrors &&
                                      responseErrors.map((error: any) => (
                                        <li className="list-group-item">
                                          {error.msg}
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    onClick={(e) => {
                                      setResponseErrors([]);
                                      setRequestError("");
                                    }}
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
      {responseErrors && responseErrors.length > 0 && (
        <div className="modal-backdrop fade show"></div>
      )}
    </div>
  );
}

export default App;
