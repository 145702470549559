/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";

/* eslint-disable valid-jsdoc */
import { camelCase, snakeCase, startCase } from "lodash";

const requestHeaders = new Headers();
requestHeaders.append("Content-Type", "application/json");

export function status(response: any) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response).catch((error) => {});
  } else {
    return Promise.reject(new Error(response.statusText)).catch((error) => {});
  }
}

export function json(response: any) {
  return response.json();
}

// export async function handleSubmit(path, data, method) {
//   mutate(
//     path,
//     await fetch(path, {
//       method: method,
//       body: JSON.stringify(data),
//     })
//       .then(status)
//       .then(json)
//       .then(function (data) {
//         return data;
//       })
//       .catch(function (error) {
//         return error;
//       })
//   );
// }

export async function performRequest(
  path: any,
  data?: any,
  method?: any,
  headers?: any
) {
  const requestConfig = {
    method: method ? method : "GET",
    url: path,
    withCredentials: false,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    validateStatus: function (status: any) {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };

  if (data) {
    return axios({ ...requestConfig, data: camelizeKeys(data) });
  }

  return axios(requestConfig);
}

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  currency: "KES",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: "currency",
});

export const amountFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const numberFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const isObject = (value: any) => {
  try {
    Object.setPrototypeOf({}, value);
    return value !== null;
  } catch (err) {
    return false;
  }
};

const convertKeys = (converter: any, object: any): any => {
  if (!isObject(object)) {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map((o) => convertKeys(converter, o));
  }

  const converted: any = {};

  Object.keys(object).map((prop) => {
    converted[converter(prop)] = convertKeys(converter, object[prop]);
    return prop;
  });

  return converted;
};

export const camelizeKeys = (object: any) => {
  return convertKeys(camelCase, object);
};

export const decamelizeKeys = (object: any) => {
  return convertKeys(snakeCase, object);
};

export const camelize = (string: string) => {
  return camelCase(string);
};

export const decamelize = (string: string) => {
  if (string.includes("_") || string.includes(".")) {
    return string;
  }
  return snakeCase(string);
};

export const titleCase = (string: string) => {
  if (!string) {
    return "";
  }
  return startCase(camelCase(string));
};
