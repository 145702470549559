import { useField } from "formik";

export function InputField({ label, required, ...props }: any) {
  const [field, meta] = useField(props);
  return (
    <div className={meta.touched ? "was-validated" : undefined}>
      <label className="form-label">
        {label} {required && <sup>*</sup>}
      </label>
      <input
        {...field}
        {...props}
        required
        className={`form-control py-2 ${meta.touched && meta.error ? "is-invalid" : ""
          }`}
      />
      {meta.touched || meta.error ? (
        <small className="invalid-feedback">{meta.error}</small>
      ) : null}
    </div>
  );
}
