import { FastField, useField } from "formik";

export function SelectField({ label, required, children, ...props }: any) {
  const [field, meta] = useField(props);

  return (
    <div className={meta.touched ? "was-validated" : undefined}>
      <label className="form-label">
        {label} {required && <sup>*</sup>}
      </label>
      {/* <select {...field} {...props} required className={`form-control py-2 ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                {children}
            </select> */}
      <FastField
        as="select"
        name={field.name}
        required
        className={`form-control py-2 ${
          meta.touched && meta.error ? "is-invalid" : ""
        }`}
        {...props}
      >
        {children}
      </FastField>
      {meta.touched && meta.error ? (
        <small className="invalid-feedback">{meta.error}</small>
      ) : null}
    </div>
  );
}
