/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from "lodash";
import moment from "moment";
import { useWizard } from "react-use-wizard";
import * as Yup from "yup";
import { InputField } from "../components/InputField";
import { SelectField } from "../components/SelectField";

const annualInterestRate = {
  "5": "5 %",
  "8": "8 %",
  "12": "12 %",
  "15": "15 %",
};

export const PRUPensionInitialValues = {
  employment: "yes",
  pension_projection: {
    applicant_dob: moment()
      .subtract(18, "years")
      .subtract(1, "week")
      .format("YYYY-MM-DD"),
    applicant_type: "salaried",
    retirement_age: 50,
    interest_rate: 5,
    beginning_fund: 0,
    monthly_contribution: 500,
    monthly_salary: 2000,
    contribution_rate: 0,
    salary_increase_rate: 0,
  },
};

export const PRUPensionValidationSchema = {
  employment: Yup.string().nullable(),
  pension_projection: Yup.object().shape({
    applicant_dob: Yup.date()
      .required()
      .max(
        moment().subtract(18, "years"),
        "You must be older than 18 years old"
      )
      .min(
        moment().subtract(65, "years"),
        "You must be younger than 65 years old"
      ),
    monthly_salary: Yup.number().when("employment", {
      is: "yes",
      then: Yup.number().required().min(2000, "Minimum salary is KES 2,000"),
      otherwise: Yup.number().nullable(),
    }),
    monthly_contribution: Yup.number().when("employment", {
      is: "no",
      then: Yup.number()
        .required()
        .min(500, "Minimum monthly contribution is KES 500"),
      otherwise: Yup.number().nullable(),
    }),
    retirement_age: Yup.number()
      .required()
      .min(50, "Minimum retirement age is 50 years")
      .max(75, "Maximum retirement age is 75 years"),
    interest_rate: Yup.number()
      .required()
      .min(5, "Minimum interest rate is 5%")
      .max(15, "Maximum interest rate is 15%"),
    salary_increase_rate: Yup.number().when("employment", {
      is: "yes",
      then: Yup.number()
        .required()
        .min(0, "Minimum annual salary increase is 0"),
      otherwise: Yup.number().nullable(),
    }),
    beginning_fund: Yup.number().required().min(0, "Minimum amount is 0"),
  }),
};

export const PRUPensionHeader = (props: any) => {
  const { activeStep } = useWizard();

  return (
    <div className="steps clearfix">
      <ul id="tabControls" role="tablist">
        <li
          role="tab"
          className={`${activeStep === 0 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-0"
            aria-controls="form-quote-p-0"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Your Goals</span>
            </div>
          </a>
        </li>
        <li
          role="tab"
          className={`${activeStep === 1 ? "current" : "disabled"}`}
        >
          <a
            id="form-quote-t-2"
            aria-controls="form-quote-p-2"
            onClick={(e) => e.preventDefault}
          >
            <div className="title">
              <span className="step-icon">
                <i className="bi bi-check"></i>{" "}
              </span>
              <span className="step-text">Personal Details</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default function PRUPensionForm(props: any) {
  const { values } = props;

  return (
    <section className="content">
      <div className="inner">
        <div className="form-row">
          <div className="form-holder">
            <SelectField
              name="employment"
              className="form-select py-2"
              required
              label="Are you employed?"
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </SelectField>
          </div>
          <div className="form-holder">
            <InputField
              label="How much have you already contributed to your pension plan?"
              type="number"
              name="pension_projection.beginning_fund"
              className="form-control py-2"
              required
            />
          </div>
        </div>
        {values.employment === "yes" && (
          <>
            <div className="form-row">
              <div className="form-holder">
                <InputField
                  label="What is your current monthly salary?"
                  type="number"
                  name="pension_projection.monthly_salary"
                  className="form-control py-2"
                  required
                  min="2000"
                />
              </div>
              <div className="form-holder">
                <InputField
                  label="What is the estimated yearly increase rate of your salary?"
                  type="number"
                  name="pension_projection.salary_increase_rate"
                  className="form-control py-2"
                  required
                />
              </div>
            </div>
          </>
        )}
        <div className="form-row">
          <div className="form-holder">
            <InputField
              label="What is your Date of Birth?"
              type="date"
              name="pension_projection.applicant_dob"
              className="form-control py-2"
              required
              max={moment()
                .subtract(18, "years")
                .subtract(1, "days")
                .format("YYYY-MM-DD")}
              min={moment().subtract(65, "years").format("YYYY-MM-DD")}
            />
          </div>
          <div className="form-holder">
            <InputField
              label="What is your desired retirement age in years?"
              type="number"
              name="pension_projection.retirement_age"
              className="form-control py-2"
              required
              min={50}
              max={75}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-holder"></div>
          <div className="form-holder"></div>
        </div>
        <div className="form-row">
          {values.employment === "yes" ? (
            <div className="form-holder">
              <InputField
                label="What is your current or future contribution rate?"
                type="number"
                name="pension_projection.contribution_rate"
                className="form-control py-2"
                required
              />
            </div>
          ) : (
            <div className="form-holder">
              <InputField
                label="What is your expected monthly contribution?"
                type="number"
                name="pension_projection.monthly_contribution"
                className="form-control py-2"
                required
                min={500}
              />
            </div>
          )}
          <div className="form-holder">
            <SelectField
              name="pension_projection.interest_rate"
              className="form-select py-2"
              required
              label="What interest rate do you prefer?"
            >
              <option></option>
              {_.map(Object.entries(annualInterestRate), (rate) => {
                return (
                  <option value={rate[0]}>{_.startCase(rate[1])} %</option>
                );
              })}
            </SelectField>
          </div>
        </div>
      </div>
    </section>
  );
}
